import React, { useEffect, useState } from "react";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db , storage } from "../firebase"; // Your Firebase config
import "../styles/ReportsSection.css";

function ReportsSection({ user }) {
  const [reports, setReports] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");


  // Fetch reports from Firestore when component loads
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "reports"));
        const reportsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Spread all fields (title, downloadURL, createdAt, etc.)
        }));
        setReports(reportsData);
      } catch (error) {
        console.error("Error fetching reports: ", error);
      }
    };

    fetchReports();
  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle title input change
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file || !title) {
      alert("Please provide a title and select a file.");
      return;
    }

    try {
      const storageRef = ref(storage, `reports/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      await addDoc(collection(db, "reports"), {
        title,
        downloadURL,
        createdAt: serverTimestamp(),
      });

      alert("File uploaded successfully!");
      setFile(null);
      setTitle("");
    } catch (error) {
      console.error("Error uploading file: ", error);
      alert("Error uploading file.");
    }
  };

  return (
    <section className="reports-section">
      <div className="container">
        <h2 className="section-title">Reports</h2>
        <ul className="report-list">
          {reports.map((report) => (
            <li key={report.id}>
              <a
                href={report.downloadURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {report.title}
              </a>
            </li>
          ))}
        </ul>
        {/* Add File Section for Authenticated Users */}
        {user && (
          <div className="add-file-section">
            <h3>Add New Report</h3>
            <input type="text" placeholder="Report Title" value={title} onChange={handleTitleChange} />
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload Report</button>
          </div>
        )}
      </div>
    </section>
  );
}

export default ReportsSection;
