import React, { useEffect, useState } from "react";
import { db } from "../firebase"; // Import Firestore and auth
import { collection, getDocs } from "firebase/firestore"; // Firestore functions
import { Link } from "react-router-dom"; // Import Link for navigation
import "../styles/MeetingsSection.css";
import AddMeetingLog from "../components/AddMeetingLog";

function MeetingsSection({ user }) {
  const [showAddLog, setShowAddLog] = useState(false); // Control visibility of form
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [error, setError] = useState(null); // State to handle any error
  const [meetings, setMeetings] = useState([]); // State to hold meetings data

  const toggleAddLog = () => {
    setShowAddLog(!showAddLog);
  };

  // useEffect to fetch meeting data
  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "meetings")); // Fetch "meetings" collection
        const meetingsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedMeetings = meetingsData.sort(
          (a, b) => b.createdAt - a.createdAt
        );

        setMeetings(sortedMeetings); // Set the meetings data
        setLoading(false); // Turn off loading
      } catch (err) {
        console.error("Error fetching meetings: ", err);
        setError("Failed to load meetings");
        setLoading(false); // Turn off loading in case of error
      }
    };

    fetchMeetings(); // Call the fetch function
  }, []);

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="meetings-section">
      <div className="container">
        <h2 className="section-title">Team Meetings & Logs</h2>

        {user ? (
          <>
            <Link onClick={toggleAddLog} className="add-meeting-button">
              {showAddLog ? "Hide Add Log" : "Add New Log"}
            </Link>
            {showAddLog && <AddMeetingLog />}
          </>
        ) : (
          <div className="login-prompt">
            <p>You need to be logged in to add a new meeting.</p>
            <Link to="/login" className="login-button">
              Login to Add Meeting
            </Link>
          </div>
        )}
        <div className="meeting-grid row">
          {meetings.map((meeting) => (
            <div className="col-md-6 col-lg-4 mb-4" key={meeting.id}>
              <div className="meeting-card p-3 border rounded shadow-sm">
                {meeting.image ? (
                  <div className="mb-3">
                    <img
                      src={meeting.image}
                      alt={meeting.title}
                      className="img-fluid meeting-image"
                    />
                  </div>
                ) : (
                  <div className="bg-light text-center py-5 rounded mb-3 placeholder-image">
                    <span className="text-muted">No Image Available</span>
                  </div>
                )}
                <h3>{meeting.title}</h3>
                <p>
                  <strong>Date:</strong> {meeting.date}
                </p>
                <p>{meeting.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MeetingsSection;
