import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db, auth, storage } from "../firebase"; // Firebase config
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "../styles/AddMeetingLog.css";

function AddMeetingLog() {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null); // To hold the selected image file
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const user = auth.currentUser;
  
    if (user) {
      try {
        let newLog = {
          title,
          date,
          description,
          author: user.email,
          createdAt: new Date(),
        };
  
        // Check if an image is provided
        if (imageFile) {
          // Upload the image to Firebase Storage
          const storageRef = ref(storage, `meeting-logs/${imageFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, imageFile);
  
          // Handle the upload process
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading file: ", error);
              alert("Error uploading image.");
            },
            async () => {
              // Get the download URL after the upload is complete
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log("File available at", downloadURL);
  
              // Add the image URL to the log object
              newLog = { ...newLog, image: downloadURL };
  
              // Save the log to Firestore after the image is uploaded
              await addDoc(collection(db, "meetings"), newLog);
              alert("Meeting log added successfully");
  
              // Reset the form
              setTitle("");
              setDate("");
              setDescription("");
              setImageFile(null);
            }
          );
        } else {
          // Save the log without an image
          await addDoc(collection(db, "meetings"), newLog);
          alert("Meeting log added successfully");
  
          // Reset the form
          setTitle("");
          setDate("");
          setDescription("");
        }
      } catch (error) {
        console.error("Error adding document: ", error);
        alert("Error adding meeting log.");
      }
    } else {
      alert("You must be logged in to add a meeting log.");
    }
  };
  

  return (
    <div className="add-meeting-log">
      <h2>Add New Meeting Log</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="date"
          placeholder="Date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files[0])} // Store selected file
        />
        <button type="submit">Add Log</button>
      </form>
    </div>
  );
}

export default AddMeetingLog;
